import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Breez
			</title>
			<meta name={"description"} content={"Створення незабутніх вражень"} />
			<meta property={"og:title"} content={"Головна | Breez"} />
			<meta property={"og:description"} content={"Створення незабутніх вражень"} />
			<meta property={"og:image"} content={"https://breezifyfix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://breezifyfix.com/img/678548856.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://breezifyfix.com/img/678548856.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://breezifyfix.com/img/678548856.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://breezifyfix.com/img/678548856.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://breezifyfix.com/img/678548856.png"} />
			<meta name={"msapplication-TileImage"} content={"https://breezifyfix.com/img/678548856.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Cards-3">
			<Override slot="SectionContent" align-items="center" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
			Створюємо незабутні враження
			</Text>
			<Text
				margin="0px 0px 30px 0px"
				font="normal 600 64px/1.2 --fontFamily-sans"
				text-align="center"
				md-margin="0px 0px 20px 0px"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Breez
			</Text>
			<Text margin="0px 0px 70px 0px" text-align="center" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
			Ми раді, що ви досліджуєте безліч можливостей, які ми пропонуємо, щоб перетворити ваші бачення в реальність. Ми прагнемо надавати виняткові послуги з планування подій, які задовольняють ваші унікальні потреби, гарантуючи, що кожен момент буде незабутнім і незвичайним.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 60px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="46px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="50px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://breezifyfix.com/img/1.jpg"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#dcfc96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
						Про Breez
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
					У Breez ми спеціалізуємося на створенні заходів на замовлення, які відображають ваш стиль і вподобання. Наша команда досвідчених професіоналів вносить креативність, точність і пристрасть у кожен проект — від інтимних зустрічей до великих святкувань. Маючи багаторічний досвід роботи в галузі, ми відточили наші навички, щоб надавати бездоганні послуги, інноваційні рішення та бездоганне виконання.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://breezifyfix.com/img/2.jpg"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#fced96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Досвідчена команда
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
					Наша команда експертів використовує багаті знання та креативність, гарантуючи, що кожна деталь ретельно спланована та виконана. Наша спеціальна команда готова допомогти вам із будь-якими запитаннями чи проблемами, які у вас можуть виникнути. Ми з нетерпінням чекаємо співпраці з вами, щоб створити подію, яка запам’ятається на довгі роки.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="80px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://breezifyfix.com/img/3.jpg"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#c5fcff"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
						Наша місія
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
					Наша місія проста: створити незабутні враження, які залишать незабутнє враження. Ми віримо, що кожна подія — це історія, яку чекають на розповідь, і ми тут, щоб допомогти вам розповісти її найзахопливішим способом. Будь то весілля, корпоративний захід чи приватна вечірка, наша мета — перевершити ваші очікування та зробити вашу подію справді особливою.
					</Text>
				</Box>
			</Box>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
			>
				Завітайте до нас
			</Button>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
			Чому варто обрати Breez?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Персоналізоване обслуговування: ми розуміємо, що немає двох однакових подій. Наш індивідуальний підхід гарантує, що ваша подія буде адаптована до ваших конкретних бажань, що робить її унікальною для вас.
					<br/><br/>
Увага до деталей: від початкової консультації до остаточного виконання ми приділяємо увагу кожній деталі, гарантуючи безперебійний досвід без стресів.

					</Text>
				</Box>
				<Image src="https://breezifyfix.com/img/4.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://breezifyfix.com/img/5.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Інноваційні рішення: ми випереджаємо останні тенденції та технології в плануванні заходів, пропонуючи інноваційні рішення, які додають вашій події особливого штриху.
					<br/><br/>
Комплексні послуги: від вибору місця та декору до організації харчування та розваг, ми надаємо повний спектр послуг, щоб задовольнити всі ваші потреби у заходах.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});